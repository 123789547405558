.projects {
    background-color: #401958;
    padding: 60px 20px;
    text-align: center;
    height: 100vh;
  }
  
  .project-cards {
    display: flex;
    justify-content: space-around;
  }
  
  .project-card {
    background-color: #f39c12;
    padding: 20px;
    margin: 10px;
    width: 250px;
    text-align: center;
    transition: transform 0.3s ease;
    cursor: pointer;
  }
  
  .project-card:hover {
    transform: scale(1.05);
  }
  h2{
    color: white;
  }
  