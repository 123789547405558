.header {
    background-color: #333;
    padding: 10px 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
  }
  
  .header nav ul {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .header nav ul li {
    margin: 0 20px;
  }
  
  .header nav ul li a {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    font-family: 'Roboto', sans-serif;
    transition: all 0.3s ease;
  }
  
  .header nav ul li a:hover {
    color: #f39c12;
    transform: scale(1.1);
  }
  