.about {
    background-color: #2c3e50;
    padding: 60px 20px;
    text-align: center;
    color: white;
    font-family: cursive;
    height: 100vh;
  }
  
  .about-content h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .about-content p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .about-content ul {
    list-style: none;
    padding: 0;
  }
  
  .about-content ul li {
    font-size: 1rem;
    margin: 5px 0;
  }
  