.contact {
    background-color: #34495e;
    padding: 60px 20px;
    text-align: center;
  }
  
  .contact input, .contact textarea {
    width: 80%;
    padding: 10px;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
  }
  
  .contact button {
    padding: 10px 20px;
    background-color: #f39c12;
    border: none;
    border-radius: 5px;
    color: white;
  }
  