.hero {
    background-color: #2c3e50;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: 'Montserrat', sans-serif;
  }
  
  .hero-content {
    text-align: center;
    animation: fadeIn 1s ease-in-out;
  }
  
  .hero h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .hero p {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .hero .btn {
    padding: 10px 20px;
    background-color: #f39c12;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .hero .btn:hover {
    background-color: #e67e22;
  }
  .profile-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
     /* Optional: to center the image vertically on the page */
  }
  
  .profile-pic {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    object-fit: cover; /* Ensures the image is properly cropped to fit the circle */
    border: 3px solid #fff; /* Optional: adds a border around the image */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Optional: adds a shadow for better visibility */
  }
  
  